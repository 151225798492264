<script>
import Draggable from 'vuedraggable';
import Render from '@/components/form-generator/render/render';
import TableRender from '@/components/form-generator/render/tableRender';
import ChildFormRender from '@/components/form-generator/render/childFormRender';

const components = {
  itemBtns (h, currentItem) {
    const { deleteItem} = this.$listeners;
    let disabled = false;
    if (currentItem.disabled === null || currentItem.disabled === undefined) {
      disabled = false;
    } else {
      disabled = currentItem.disabled;
    }
    let visible = true;
    if (currentItem.__config__.visible === null || currentItem.__config__.visible === undefined) {
      visible = true;
    } else {
      visible = currentItem.__config__.visible;
    }

    const updateVisibleAndDisableSetting = (disable, visible)=>{
      this.$set(currentItem, 'disabled', disable);
      this.$set(currentItem.__config__, 'visible', visible);
    };
    // 可编辑 disabled:false,visible:true
    // 仅可见 disabled:true,visible:true
    // 隐藏  disabled:false,visible:false
    let drawingBtnSetting = [];
    // 分组无可编辑、仅可见、隐藏按钮
    if (currentItem.__config__.layout !== 'groupRowFormItem') {
      if (currentItem.__config__.tag !== 'span') {
        drawingBtnSetting.push({
          classList: {'active': !disabled && visible, 'left_btn_item': true},
          icon: 'icon iconfont icon-bianji1',
          btnText: '可编辑',
          clickHandler: ()=>{
            updateVisibleAndDisableSetting(false, true);
          },
        });
        drawingBtnSetting.push({
          classList: {'active': disabled && visible, 'left_btn_item': true},
          icon: 'icon iconfont icon-kejian',
          btnText: '仅可见',
          clickHandler: ()=>{
            updateVisibleAndDisableSetting(true, true);
          },
        });
      }

      if (!currentItem.__config__.isReservedField) {
        drawingBtnSetting.push({
          classList: {'active': !visible, 'left_btn_item': true},
          icon: 'icon iconfont icon-yincang',
          btnText: '隐藏',
          clickHandler: ()=>{
            let isSpan = currentItem.__config__.tag === 'span';
            updateVisibleAndDisableSetting(false, isSpan ? !visible : false);
          },
        });
      }
    }

    let ret = [];
    if (drawingBtnSetting.length > 0) {
      ret.push(<div class="drawing-item-left-btn-list">
        {
          drawingBtnSetting.map(i => (
            <div title={i.btnText} class={i.classList} onClick={
              (event) => {
                i.clickHandler();
                event.stopPropagation();
              }}>
              <i class={i.icon} /><span>{ i.btnText }</span>
            </div>
          ))
        }
      </div>,
      );
    }

    if (this.activeProcessId === currentItem.__config__.stepSourceId && !currentItem.__config__.isReservedField) {
      ret.push(
        <el-popconfirm
          placement="left"
          class="drawing-item-delete"
          close-delay={300}
          title="确认是否删除该组件？"
          hide-icon={true}
          cancel-button-type="default"
          confirm-button-type="primary"
          nativeOnClick={(event) => {
            event.stopPropagation();
          }}
          onConfirm={() => {
            deleteItem(currentItem);
          }}
        >
          <div class="drawing-item-delete" title="删除" slot="reference">
            <i class="icon iconfont icon-shanchu" /><span>删除</span>
          </div>
        </el-popconfirm>,
      );
    }
    return ret;
  },
};

const layouts = {
  colFormItem (h, currentItem) {
    const { activeItem } = this.$listeners;
    const config = currentItem.__config__;
    const child = renderChildren.apply(this, arguments);
    let className = this.activeId === config.renderKey ? 'drawing-item active-from-item' : 'drawing-item';
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered';
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    // 设计器中通过cover覆盖，阻止控件交互、获取焦点
    const cover = <div style={{'position': 'absolute', 'top': 0, 'left': 0, 'bottom': 0, 'right': 0, 'z-index': 10}}></div>;
    return (
      <el-col class={className} data-id={config.renderKey}
        nativeOn={{
          '!click': event => {
            activeItem(currentItem);
            // 如果点击的是显影、删除按钮需要特殊处理
            if (event.target?.parentElement?.className
              && (event.target?.parentElement?.className.indexOf('left_btn_item') >= 0
              || event.target?.parentElement?.className.indexOf('drawing-item-delete') >= 0)) {
              return;
            }
            event.stopPropagation();
          },
        }}>
        <el-form-item label-width={labelWidth}
          label={config.showLabel ? config.label : ''} required={config.required}>
          <Render key={config.renderKey} conf={currentItem} mode="preview" activeProcessId={this.activeProcessId} onInput={ event => {
            this.$set(config, 'defaultValue', event);
          }}>
            {child}
          </Render>
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
        {cover}
      </el-col>
    );
  },
  groupRowFormItem (h, currentItem) {
    const { activeItem } = this.$listeners;
    const config = currentItem.__config__;
    const className = this.activeId === config.renderKey
      ? 'drawing-row-item active-from-item'
      : 'drawing-row-item';
    let child = renderChildren.apply(this, arguments);
    return (
      <el-col >
        <el-row gutter={config.gutter} class={className}
          nativeOn={{
            'click': event => {
              activeItem(currentItem);
              event.stopPropagation();
            },
          }}>
          <el-col span={24}>
            <div class="group-title">{config.label}</div>
            <Draggable list={config.children || []}
              groupKey={config.renderKey}
              onAdd={(event)=>this.$emit('add', event)}
              move={this.move}
              group="componentsGroup" class="drag-wrapper group-wrapper">
              {child}
            </Draggable>
          </el-col>
          {components.itemBtns.apply(this, arguments)}
        </el-row>
      </el-col>
    );
  },
  tableColumnFormItem (h, currentItem) {
    const { activeItem } = this.$listeners;
    const config = currentItem.__config__;
    let className = this.activeId === config.renderKey ? 'drawing-item active-from-item' : 'drawing-item';
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered';
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    // 设计器中通过cover覆盖，阻止控件交互、获取焦点
    const cover = <div style={{'position': 'absolute', 'top': 0, 'left': 0, 'bottom': 0, 'right': 0, 'z-index': 10}}></div>;
    return (
      <el-col class={className} data-id={config.renderKey}
        nativeOn={{
          '!click': event => {
            activeItem(currentItem);
            // 如果点击的是显影、删除按钮需要特殊处理
            if (event.target?.parentElement?.className
              && (event.target?.parentElement?.className.indexOf('left_btn_item') >= 0
              || event.target?.parentElement?.className.indexOf('drawing-item-delete') >= 0)) {
              return;
            }
            event.stopPropagation();
          },
        }}>
        <el-form-item label-width={labelWidth}
          label={config.showLabel ? config.label : ''} required={config.required}>
          <TableRender scheme={currentItem} mode="preview"/>
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
        {cover}
      </el-col>
    );
  },
  childFormItem (h, currentItem) {
    const { activeItem } = this.$listeners;
    const config = currentItem.__config__;
    let className = this.activeId === config.renderKey ? 'drawing-item active-from-item' : 'drawing-item';
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered';
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    // 设计器中通过cover覆盖，阻止控件交互、获取焦点
    const cover = <div style={{'position': 'absolute', 'top': 0, 'left': 0, 'bottom': 0, 'right': 0, 'z-index': 10}}></div>;
    return (
      <el-col class={className} data-id={config.renderKey}
        nativeOn={{
          '!click': event => {
            activeItem(currentItem);
            // 如果点击的是显影、删除按钮需要特殊处理
            if (event.target?.parentElement?.className
              && (event.target?.parentElement?.className.indexOf('left_btn_item') >= 0
              || event.target?.parentElement?.className.indexOf('drawing-item-delete') >= 0)) {
              return;
            }
            event.stopPropagation();
          },
        }}>
        <el-form-item label-width={labelWidth}
          label={config.showLabel ? config.label : ''} required={config.required}>
          <ChildFormRender schema={currentItem} mode="preview"/>
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
        {cover}
      </el-col>
    );
  },
};

function renderChildren (h, currentItem) {
  const config = currentItem.__config__;
  if (!Array.isArray(config.children)) return null;
  return config.children.map((el, i) => {
    const layout = layouts[el.__config__.layout];
    if (layout) {
      return layout.call(this, h, el, i, config.children);
    }
    return layoutIsNotFound.call(this);
  });
}

function layoutIsNotFound () {
  throw new Error(`没有与${this.currentItem.__config__.layout}匹配的layout`);
}

export default {
  components: {
    Render,
    TableRender,
    ChildFormRender,
    Draggable,
  },
  props: [
    'currentItem',
    'activeId',
    'formConf',
    'move',
    'activeProcessId',
  ],
  render (h) {
    const layout = layouts[this.currentItem.__config__.layout];
    if (layout) {
      return layout.call(this, h, this.currentItem);
    }
    return layoutIsNotFound.call(this);
  },
};
</script>
